<template>
  <div>
    <div v-if="topic">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <div class="page-header">
          <h4 id="top">Editing Auidiences: <small>{{ topic.title }}</small></h4>
        </div>
      </div>

      <!-- Error Alert -->
      <danger-alert :errors="errors"></danger-alert>
      <form v-on:submit.prevent="editTopic">
        <div class="form-group text-left">
          <label for="topic-name">Audience Title</label>
          <input
            type="text"
            class="form-control"
            v-model="topic.title"
            placeholder="name"
            id="topic-name"
            name="topic-name"
          />
        </div>
        <div class="form-group text-left">
          <label for="topic-archived">Is Archived?</label>
          <b-checkbox id="topic-archived" v-model="topic.is_archived"/>
        </div>

        <div class="form-group float-right">
          <div class="btn-group">
            <router-link :to="{ name: 'TopicShow', params: { id: topic.id } }" tag="a" role="button" class="btn btn-secondary">Back</router-link>
            <input type="submit" class="btn btn-success" value="Update Audience" />
          </div>
        </div>
      </form>
    </div>
    <loading-overlay v-else></loading-overlay>
  </div>
</template>

<script>

import { TopicService } from '@/common/services/topic.service';
import DangerAlert from '@/common/components/danger-alert.vue';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'TopicEdit',
  components: {
    DangerAlert,
    LoadingOverlay,
  },
  data() {
    return {
      topic: null,
      errors: [],
    };
  },
  created() {
    TopicService.get(this.$route.params.id)
      .then(({ data }) => {
        this.topic = data;
      });
  },
  methods: {
    editTopic() {
      const payload = { title: this.topic.title, is_archived: this.topic.is_archived };
      TopicService.update(this.$route.params.id, payload)
        .then(({ data }) => {
          this.$router.push({ name: 'TopicShow', params: { id: this.topic.id } });
          this.$notify({
            title: 'Edited Topic',
            text: `Successfully edited ${data.title}!`,
            type: 'success',
            width: 350,
          });
        })
        .catch((err) => {
          this.errors = err.response.data.error;
          this.$notify({
            title: 'Could not submit form!',
            text: this.errors.join(' '),
            type: 'error',
            width: 350,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/topics";
</style>
